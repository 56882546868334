import React, { Component } from "react";
import { Bee3D } from "../bee3d/dist/js/bee3D.js";
import { GoChevronRight, GoChevronLeft } from "react-icons/lib/go";
import { slugify } from "./getData";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.carousel = React.createRef();
    this.state = {
      voorstellingen: props.voorstellingen
    };
  }
  componentDidMount() {
    this.slider = new Bee3D(this.carousel.current, {
      effect: "classic",
      focus: 0,
      listeners: {
        keys: true,
        touches: true,
        scroll: true
      },
      navigation: {
        enabled: true
      },
      loop: {
        enabled: true,
        continuous: false
      }
    });
  }
  componentWillReceiveProps() {}

  // shouldComponentUpdate() {
  //   return true;
  // }
  loadPage = e => {
    e.persist();
    this.props.updatePage("");
    setTimeout(() => {
      this.props.history.push(
        "/" +
          e.target.dataset.lang +
          "/event/" +
          e.target.dataset.id +
          "/" +
          e.target.dataset.page
      );
    }, 800);
  };
  render() {
    return (
      <div>
        <div ref={this.carousel} className="bee3D--parent">
          <button aria-label="previous" className="bee3D--nav bee3D--nav__prev">
            <GoChevronLeft size={30} color="#F6E5A3" />
          </button>
          {this.state.voorstellingen.map((voorstelling, index) => {
            return (
              <section className="bee3D--slide" key={voorstelling.id}>
                <img
                  src={voorstelling.affiche.url}
                  data-page={slugify(voorstelling["title_" + this.props.lang])}
                  data-id={voorstelling.id}
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  onClick={this.loadPage}
                  alt=""
                />
              </section>
            );
          })}
          <button aria-label="next" className="bee3D--nav bee3D--nav__next">
            <GoChevronRight size={30} color="#F6E5A3" />
          </button>
        </div>
      </div>
    );
  }
}
