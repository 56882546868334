import React, { Component } from "react";
import { Animated } from "react-animated-css";
import bg1 from "../images/tourtickets-pancarte.png";
import { Helmet } from "react-helmet";
import Preloader from "./Preloader";
import ReactHtmlParser from "react-html-parser";
import { getData, search, slugify, month_nl } from "./getData";
import overlay2 from "../images/overlay2.png";

export default class TourTickets extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.contentOverRef = React.createRef();
    this.getData("list", "voorstellingen", "lopend=1", this.getTourEnTickets);
    this.state = {
      info_nl: "meer info volgt",
      info_fr: "plus d'infos plus tard",
      info_en: "more info will follow",
      preloaded: false,
      loaded: 0,
      all: true,
      categories: [],
      tour_en_tickets: [],
      voorstellingen: [],
      futureItems: [],
      wrapperSettings: [
        {
          top: "auto",
          height: "56%",
          width: "85%",
          bottom: "7%",
          left: "8%"
        }
      ],
      colorSettings: [
        {
          color: "#EECBA0"
        }
      ]
    };
  }
  updatePreloaded = () => {
    let copy = this.state.loaded;
    copy++;
    this.setState({ loaded: copy });
    if (copy === 2) {
      this.setState({ preloaded: true });
    }
  };
  getTourEnTickets = () => {
    let booleans = this.state.voorstellingen.map((voorstelling, index) => {
      let returnValue = true;
      if (this.props.match.params.event !== undefined) {
        returnValue =
          slugify(voorstelling["title_" + this.props.lang]) ===
          slugify(this.props.match.params.event);
      }
      return { id: voorstelling.id, value: returnValue };
    });
    this.setState({
      categories: booleans
    });
    this.getData("list", "tour_en_tickets", "", this.renderTickets);
  };
  compare = (a, b) => {
    let date1_txt = a.data.split(", ")[0].split("/");
    let date1 = new Date(date1_txt[2], date1_txt[1] - 1, date1_txt[0]);

    let date2_txt = b.data.split(", ")[0].split("/");
    let date2 = new Date(date2_txt[2], date2_txt[1] - 1, date2_txt[0]);

    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
  };
  renderTickets = () => {
    let futureItems = this.state.tour_en_tickets.filter(item => {
      let dates = item.data.split(", ").reverse();
      var today = new Date();
      today.setDate(today.getDate() - 7);
      const dateParts = dates[0].split("/");
      var founddate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      return founddate >= today;
    });
    let orderedItems = futureItems.sort(this.compare);
    console.log(orderedItems)
    let groupedDateItems = orderedItems.map((item, index) => {
      let itemData = item.data.split(", ");
      let groupedDataArray = [];
      let groupIndex = -1;
      let monthYearCheck = "";
      let groupString = "";
      for (let date of itemData) {
        const dateParts = date.split("/");
        if (monthYearCheck !== dateParts[1] - 1 + "-" + dateParts[2]) {
          groupIndex++;
          groupString = date;
          groupedDataArray.push(groupString);
          monthYearCheck = dateParts[1] - 1 + "-" + dateParts[2];
        } else {
          groupString += ", " + date;
          groupedDataArray[groupIndex] = groupString;
        }
      }
      let groupedItem = item;
      groupedItem.data = groupedDataArray;
      return groupedItem;
    });

    this.setState({
      futureItems: groupedDateItems
    });
  };

  componentWillMount() {
    this.props.updatePage("tour-tickets");
  }
  componentDidMount = () => {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
    this.show();
  };
  show = () => {
    setTimeout(() => {
      if (this.state.preloaded) {
        this.contentOverRef.current.classList.add("visible");
      } else {
        this.show();
      }
    }, 1000);
  };
  onCheck = e => {
    this.flipValues(e);
  };
  handleLabelClick = e => {
    this.flipValues(e);
  };
  flipValues(e) {
    const copy = this.state.categories;
    const target = e.target;
    const nr = target.getAttribute("index");
    search(copy, nr, "id").value = !search(copy, nr, "id").value;

    this.setState({
      categories: copy
    });
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo | Tour &amp; Tickets</title>
          <link
            rel="canonical"
            href={"https://www.circusronaldo.be" + this.props.location.pathname}
          />
          <link rel="alternate" media="only screen and (max-width: 640px)" href={"https://m.circusronaldo.be/voorstelling/tour_en_tickets"} />
          <meta
            property="og:title"
            content="Circus Ronaldo | Tour &amp; Tickets"
          />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content="Bekijk hier alle info omtrent tour &amp; tickets voor de voorstellingen van Circus Ronaldo."
          />
          <meta
            name="description"
            content="Bekijk hier alle info omtrent tour &amp; tickets voor de voorstellingen van Circus Ronaldo."
          />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          src={bg1}
          className="hidden"
          alt="geschiedenis"
        />
        <img
          onLoad={this.updatePreloaded}
          src={overlay2}
          className="hidden"
          alt="geschiedenis"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <Animated
            animationIn="bounceInDown"
            animationInDelay={0}
            animationOutDelay={0}
            animationOut="bounceOutUp"
            isVisible={this.props.page === "" ? false : true}
          >
            <div className="pancarte2Holder tour">
              <div className="pancarte2">
                <div className="pancarteImage2">
                  <img
                    className="pancarteImage2Image"
                    src={bg1}
                    alt="tour tickets"
                  />
                  <div className="checkboxes">
                    {this.state.categories.length >= 1 &&
                      this.state.voorstellingen.map((voorstelling, index) => {
                        return (
                          <div key={voorstelling.id}>
                            <input
                              type="checkbox"
                              index={voorstelling.id}
                              checked={
                                search(
                                  this.state.categories,
                                  voorstelling.id,
                                  "id"
                                ).value
                              }
                              onChange={this.onCheck}
                            />
                            <label
                              index={voorstelling.id}
                              onClick={this.handleLabelClick}
                            >
                              <span index={voorstelling.id} />
                              {voorstelling["title_" + this.props.lang]}
                            </label>
                          </div>
                        );
                      })}
                  </div>

                  <div
                    className="textWrapper"
                    ref={this.contentOverRef}
                    style={this.state.wrapperSettings[0]}
                  >
                    <table className="table">
                      <tbody>
                        {this.state.futureItems.map((item, index) => {
                          return (
                            <tr
                              key={item.id}
                              className={
                                search(
                                  this.state.categories,
                                  item.voorstellingen_id,
                                  "id"
                                ).value
                                  ? ""
                                  : "hidden"
                              }
                            >
                              <td>
                                {
                                  search(
                                    this.state.voorstellingen,
                                    item.voorstellingen_id,
                                    "id"
                                  )["title_" + this.props.lang]
                                }
                              </td>
                              <td>
                                {item.data.map((dateGroup, index) => {
                                  let data = dateGroup.split(", ");
                                  let dataString = "";
                                  let month = "";
                                  let year = "";
                                  let first = true;
                                  for (let date of data) {
                                    if (first) {
                                      first = false;
                                    } else {
                                      dataString += ", ";
                                    }
                                    const splittedDate = date.split("/");
                                    dataString += splittedDate[0];
                                    month = splittedDate[1] - 1;
                                    year = splittedDate[2];
                                  }
                                  dataString +=
                                    " " +
                                    month_nl[month] +
                                    " " +
                                    year +
                                    "<br />";
                                  return ReactHtmlParser(dataString);
                                })}
                              </td>
                              <td>{item["plaats_" + this.props.lang]}</td>
                              {item.link !== "" && item.link !== " " && item.link !== null && (
                                <td>
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Tickets
                                  </a>
                                </td>
                              )}
                              {(item.link === "" || item.link === " " || item.link === null) && (
                                <td>
                                  {item.meer_info_volgt === "0" && (
                                    <span>-</span>
                                  )}
                                  {item.meer_info_volgt === "1" && (
                                    <span>
                                      {this.state["info_" + this.props.lang]}
                                    </span>
                                  )}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                  <img src={overlay2} className="overlay2" alt="" />
                </div>
              </div>
            </div>
          </Animated>
        )}
      </div>
    );
  }
}
