import React from "react";
import icon from "../images/loader.svg";
const Preloader = props => (
  <div>
    <div className="preloaderHolder">
      <img src={icon} alt="preloading" />
    </div>
  </div>
);

export default Preloader;
