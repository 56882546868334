import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Carousel from "./Carousel";
import { Animated } from "react-animated-css";
import { getData } from "./getData";
import Preloader from "./Preloader";

export default class Voorstellingen extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.getData("list", "voorstellingen", "lopend=1");
    this.state = {
      voorstellingen: []
    };
  }
  componentWillMount() {
    this.props.updatePage("voorstellingen");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
  }

  render() {
    return (
      <div className="events">
        <Helmet>
          <title>Circus Ronaldo | Voorstellingen</title>
          <link
            rel="canonical"
            href={"https://www.circusronaldo.be" + this.props.location.pathname}
          />
          <link rel="alternate" media="only screen and (max-width: 640px)" href="https://m.circusronaldo.be/voorstellingen" />
          <meta property="og:title" content="Circus Ronaldo | Voorstellingen" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content="Alle voorstellingen van Circus Ronaldo."
          />
          <meta
            name="description"
            content="Alle voorstellingen van Circus Ronaldo."
          />
        </Helmet>
        {this.state.voorstellingen.length === 0 && <Preloader />}
        {this.state.voorstellingen.length >= 1 && (
          <Animated
            className="anim"
            animationIn="bounceInDown"
            animationInDelay={200}
            animationOutDelay={200}
            animationOut="bounceOutUp"
            isVisible={this.props.page === "" ? false : true}
          >
            <Carousel
              lang={this.props.lang}
              voorstellingen={this.state.voorstellingen}
              updatePage={this.props.updatePage}
              history={this.props.history}
              location={this.props.location}
            />
          </Animated>
        )}
      </div>
    );
  }
}
