import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import bg1 from "../images/media-pancarte.png";
import bg2 from "../images/mini_pancarte.png";
import Gallery from "./Gallery";
import { getData } from "./getData";
import Preloader from "./Preloader";
//import Script from "react-load-script";

export default class Media extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.getData("list", "media_album", "", this.getMedia);
    this.state = {
      preloaded: false,
      loaded: 0,
      media_album: [],
      media: [],
      showGallery: false,
      active: parseInt(props.match.params.album, 10) || 0
    };
  }
  showGallery = () => {
    this.setState({
      showGallery: true
    });
  };
  getMedia = albums_id => {
    if (albums_id === undefined) {
      if (this.props.match.params.album !== undefined) {
        this.setState({
          active: this.props.match.params.album
        });
        this.getData(
          "list",
          "media",
          "media_album_id=" + parseInt(this.props.match.params.album, 10),
          this.showGallery
        );
      } else {
        this.setState({
          active: this.state.media_album[0].id
        });
        this.getData(
          "list",
          "media",
          "media_album_id=" + this.state.media_album[0].id,
          this.showGallery
        );
      }
    } else {
      this.setState({
        showGallery: false
      });
      this.getData(
        "list",
        "media",
        "media_album_id=" + albums_id,
        this.showGallery
      );
    }
  };
  componentWillMount() {
    this.props.updatePage("media");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
  }
  updatePreloaded = () => {
    let copy = this.state.loaded;
    copy++;
    this.setState({ loaded: copy });
    if (copy === 2) {
      this.setState({ preloaded: true });
    }
  };
  render = () => {
    return (
      <div>
        {/* <Script url="https://code.jquery.com/jquery-3.3.1.min.js" />
        <Script url="https://cdnjs.cloudflare.com/ajax/libs/galleria/1.5.7/galleria.min.js" /> */}
        <Helmet>
          <title>Circus Ronaldo | Media</title>
          <link
            rel="canonical"
            href={"https://www.circusronaldo.be" + this.props.location.pathname}
          />
          <link rel="alternate" media="only screen and (max-width: 640px)" href={"https://m.circusronaldo.be/media/swing"} />
          <meta property="og:title" content="Circus Ronaldo | Media" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content="Bekijk hier foto's en videos van de voorstellingen van Circus Ronaldo."
          />
          <meta
            name="description"
            content="Bekijk hier foto's en videos van de voorstellingen van Circus Ronaldo."
          />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={bg1}
          alt="voorstellingen"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={bg2}
          alt="voorstellingen"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <div>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={0}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="pancarte">
                <img className="pancarteImage" src={bg1} alt="media" />
                <div>
                  {this.state.showGallery && (
                    <Gallery lang={this.props.lang} media={this.state.media} />
                  )}
                </div>
              </div>
            </Animated>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={200}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="pancarteSmall">
                <img className="pancarteImageSmall" src={bg2} alt="media" />
                <ul>
                  {this.state.media_album.map((albums, index) => {
                    return (
                      <li key={albums.id}>
                        <a
                          href="/media"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              active: albums.id
                            });
                            this.getMedia(albums.id);
                          }}
                          className={
                            this.state.active === albums.id ? "active" : ""
                          }
                        >
                          {albums["title_" + this.props.lang]}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Animated>
          </div>
        )}
      </div>
    );
  };
}
