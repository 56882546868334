var maxBy = require("lodash.maxby");
var minBy = require("lodash.minby");

export const cleanUrl = input => {
  return input
    .replace(/(^-+|[^a-zA-Z0-9/_| -]+|-+$)/g, "")
    .toLowerCase()
    .replace(/[/_| -]+/g, "-");
};

export const getMinOrMax = (markersObj, minOrMax, latOrLng) => {
  console.log(markersObj, minOrMax, latOrLng);
  if (minOrMax === "max") {
    return maxBy(markersObj, function(value) {
      return value[latOrLng];
    })[latOrLng];
  } else {
    return minBy(markersObj, function(value) {
      return value[latOrLng];
    })[latOrLng];
  }
};

export const getBounds = markersObj => {
  //console.log(markersObj);
  var maxLat = getMinOrMax(markersObj, "max", "lat");
  var minLat = getMinOrMax(markersObj, "min", "lat");
  var maxLng = getMinOrMax(markersObj, "max", "lng");
  var minLng = getMinOrMax(markersObj, "min", "lng");

  var southWest = [minLng, minLat];
  var northEast = [maxLng, maxLat];
  return [southWest, northEast];
};
