import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";

import Preloader from "./Preloader";

import tourTickets from "../images/tour-tickets.png";
import tourTicketsOver from "../images/tourtickets-over.png";
import contact from "../images/contact.png";
import contactOver from "../images/contact-over.png";
import media from "../images/media.png";
import mediaOver from "../images/media-over.png";

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.voorstellingenOverRef = React.createRef();
    this.mediaOverRef = React.createRef();
    this.contactOverRef = React.createRef();
    this.tourTicketsOverRef = React.createRef();
    this.geschiedenisOverRef = React.createRef();
    this.state = {
      preloaded: false,
      loaded: 0
    };
  }
  componentWillMount() {
    this.props.updatePage("welcome");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(false, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
  }
  updatePreloaded = () => {
    let copy = this.state.loaded;
    copy++;
    this.setState({ loaded: copy });
    if (copy === 10) {
      this.setState({ preloaded: true });
    }
  };
  loadPage = e => {
    e.persist();
    this.props.updatePage("");
    setTimeout(() => {
      this.props.history.push(
        "/" + e.target.dataset.lang + "/" + e.target.dataset.page
      );
    }, 1000);
  };
  getGeschiedenisFromLang = () => {
    let caption = "";
    switch (this.props.lang) {
      case "nl":
        caption = "geschiedenis";
        break;
      case "fr":
        caption = "histoire";
        break;
      case "en":
        caption = "history";
        break;
      default:
        caption = "";
    }
    return caption;
  };
  getVoorstellingenFromLang = () => {
    let caption = "";
    switch (this.props.lang) {
      case "nl":
        caption = "voorstellingen";
        break;
      case "fr":
        caption = "spectacles";
        break;
      case "en":
        caption = "shows";
        break;
      default:
        caption = "";
    }
    return caption;
  };
  onMouseOver = e => {
    this[e.target.alt + "OverRef"].current.style.visibility = "visible";
  };
  onMouseOut = e => {
    this[e.target.alt + "OverRef"].current.style.visibility = "hidden";
  };
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo | Welcome</title>
          <link
            rel="canonical"
            href={"https://www.circusronaldo.be" + this.props.location.pathname}
          />
          <link rel="alternate" media="only screen and (max-width: 640px)" href="https://m.circusronaldo.be" />
          <meta property="og:title" content="Circus Ronaldo | Welcome" />
          <meta property="og:type" content="activity" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:image"
            content="https://www.circusronaldo.be/images/logo.png"
          />
          <meta
            property="og:site_name"
            content="https://www.circusronaldo.be"
          />
          <meta
            property="og:description"
            content="Welkom op de website van Circus Ronaldo"
          />
          <meta
            name="description"
            content="Welkom op de website van Circus Ronaldo"
          />
          <meta
            name="keywords"
            content="circus,ronaldo,fidelis,fortibus,swing,wonderlijke,kerst,cucina,dell,arte,Nanosh,carnivale"
          />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={require(`../images/voorstellingen-${this.props.lang}.png`)}
          alt="voorstellingen"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={require(`../images/voorstellingen-over-${this.props.lang}.png`)}
          alt="voorstellingen"
        />
        <img
          onLoad={this.updatePreloaded}
          src={tourTickets}
          className="hidden"
          alt="tour tickets"
        />
        <img
          onLoad={this.updatePreloaded}
          src={tourTicketsOver}
          className="hidden"
          alt="tour tickets"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={require(`../images/geschiedenis-${this.props.lang}.png`)}
          alt="geschiedenis"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={require(`../images/geschiedenis-over-${this.props.lang}.png`)}
          alt="geschiedenis"
        />
        <img
          onLoad={this.updatePreloaded}
          src={contact}
          className="hidden"
          alt="tour tickets"
        />
        <img
          onLoad={this.updatePreloaded}
          src={contactOver}
          className="hidden"
          alt="tour tickets"
        />
        <img
          onLoad={this.updatePreloaded}
          src={media}
          className="hidden"
          alt="tour tickets"
        />
        <img
          onLoad={this.updatePreloaded}
          src={mediaOver}
          className="hidden"
          alt="media"
        />

        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <div>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={0}
              animationOutDelay={0}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParentTourTickets">
                <img
                  className="tourTickets"
                  src={tourTickets}
                  alt="tour en tickets"
                  useMap="#tourTickets"
                />
                <img
                  className="tourTicketsOver"
                  ref={this.tourTicketsOverRef}
                  data-page="tour-tickets"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  src={tourTicketsOver}
                  alt="tourTickets"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="tourTickets" id="tourTickets">
                <area
                  data-page="tour-tickets"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  alt="tourTickets"
                  shape="poly"
                  coords="26,641,79,641,80,625,123,633,125,617,151,626,174,622,188,590,205,622,223,628,251,616,255,631,299,625,298,639,321,642,359,640,365,661,348,703,353,712,338,725,322,724,305,717,287,736,265,741,264,746,225,730,198,725,187,731,168,723,125,746,116,740,103,738,84,725,74,717,62,719,48,725,30,717,27,703,18,692,15,657,17,643"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={100}
              animationOutDelay={100}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParentContact">
                <img
                  className="contact"
                  src={contact}
                  alt="tour en tickets"
                  useMap="#contact"
                />
                <img
                  className="contactOver"
                  ref={this.contactOverRef}
                  data-page="contact"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  src={contactOver}
                  alt="contact"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="contact" id="contact">
                <area
                  data-page="contact"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  alt="contact"
                  shape="poly"
                  coords="115,823,123,830,136,830,145,823,158,824,170,839,177,854,178,844,185,833,195,833,201,850,213,848,215,862,207,856,204,866,207,875,220,887,223,899,221,905,202,910,209,919,204,934,190,931,177,926,168,944,156,925,140,920,129,931,111,965,97,930,85,923,71,924,59,942,50,924,27,929,15,922,18,910,21,904,7,901,3,881,18,872,21,861,16,857,12,861,5,857,17,847,27,845,36,829,46,840,49,850,64,827,81,819"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={200}
              animationOutDelay={200}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParentGeschiedenis">
                <img
                  className="geschiedenis"
                  src={require(`../images/geschiedenis-${this.props.lang}.png`)}
                  alt="geschiedenis"
                  useMap="#geschiedenis"
                />
                <img
                  className="geschiedenisOver"
                  ref={this.geschiedenisOverRef}
                  data-page={this.getGeschiedenisFromLang()}
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  src={require(`../images/geschiedenis-over-${
                    this.props.lang
                  }.png`)}
                  alt="geschiedenis"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="geschiedenis" id="geschiedenis">
                <area
                  data-page={this.getGeschiedenisFromLang()}
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  alt="geschiedenis"
                  shape="poly"
                  coords="185,530,198,526,216,551,235,545,259,541,275,557,312,544,329,548,357,597,361,615,346,625,330,657,308,665,278,644,251,640,234,660,228,638,221,638,201,650,188,646,166,650,149,636,137,663,119,641,91,646,77,657,62,661,37,647,25,628,11,611,12,601,32,573,49,540,78,552,95,560,123,541,142,549,158,550,177,525"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={300}
              animationOutDelay={300}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParentVoorstellingen">
                <img
                  className="voorstellingen"
                  src={require(`../images/voorstellingen-${
                    this.props.lang
                  }.png`)}
                  alt="voorstellingen"
                  useMap="#voorstellingen"
                />
                <img
                  className="voorstellingenOver"
                  ref={this.voorstellingenOverRef}
                  data-page={this.getVoorstellingenFromLang()}
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  src={require(`../images/voorstellingen-over-${
                    this.props.lang
                  }.png`)}
                  alt="voorstellingen"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="voorstellingen" id="voorstellingen">
                <area
                  data-page={this.getVoorstellingenFromLang()}
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  alt="voorstellingen"
                  shape="poly"
                  coords="150,355,163,348,178,365,166,376,181,370,200,368,227,380,225,369,235,350,251,364,268,362,270,378,258,368,256,379,271,383,284,371,305,387,330,383,346,394,348,380,358,395,351,401,370,409,377,419,381,441,365,470,359,461,309,482,293,471,277,482,251,473,219,490,200,478,176,484,153,470,133,481,108,469,88,477,57,464,26,467,23,449,17,429,18,407,46,399,43,386,52,381,50,391,57,392,69,382,93,386,113,368,134,381,148,373,142,366,137,375,131,360,142,357"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={400}
              animationOutDelay={400}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParentMedia">
                <img
                  className="media"
                  src={media}
                  alt="media"
                  useMap="#media"
                />
                <img
                  className="mediaOver"
                  ref={this.mediaOverRef}
                  data-page="media"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  src={mediaOver}
                  alt="media"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="media" id="media">
                <area
                  data-page="media"
                  data-lang={this.props.location.pathname.substr(1, 2)}
                  alt="media"
                  shape="poly"
                  coords="129,448,144,441,148,450,144,454,135,456,143,461,175,447,196,449,204,446,208,455,202,462,208,473,218,470,224,475,214,485,228,492,240,506,236,517,223,531,215,556,195,540,188,544,195,568,187,560,174,551,163,567,129,544,101,564,88,562,80,549,61,567,58,560,68,536,58,536,42,555,33,543,30,521,16,513,18,498,29,486,40,479,35,470,45,470,60,456,52,444,70,445,85,443,105,456,121,457,106,449,113,441"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
          </div>
        )}
      </div>
    );
  }
}
