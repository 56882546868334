/* eslint-disable */

import axios from "axios";
export function getData(action, object, filter, callback) {
  axios
    .request({
      method: "post",
      baseURL: process.env.REACT_APP_API_URL,
      data: {
        action: "login",
        username: process.env.REACT_APP_USER_CODE,
        password: process.env.REACT_APP_SECRET_CODE
      }
    })
    .then(res => {
      const token = res.data.JWT;

      axios
        .get(
          process.env.REACT_APP_API_URL +
            "?action=" +
            action +
            "&object=" +
            object +
            "&" +
            filter,
          {
            headers: {
              "X-Authorization": "Bearer " + token
            }
          }
        )
        .then(res => {
          //console.log(res.data);
          this.setState(
            {
              [object]: res.data[object]
            },
            callback
          );
        });
    });
}

export function search(array, key, prop) {
  // Optional, but fallback to key['name'] if not selected
  prop = typeof prop === "undefined" ? "name" : prop;

  for (var i = 0; i < array.length; i++) {
    if (array[i][prop] === key) {
      return array[i];
    }
  }
}

export const month_en = [
  "JAN",
  "FEB",
  "MAA",
  "APR",
  "MEI",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OKT",
  "NOV",
  "DEC"
];
export const month_nl = [
  "JAN",
  "FEB",
  "MAA",
  "APR",
  "MEI",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OKT",
  "NOV",
  "DEC"
];
export const month_fr = [
  "JAN",
  "FEB",
  "MAA",
  "APR",
  "MEI",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OKT",
  "NOV",
  "DEC"
];

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}
