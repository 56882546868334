import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { getData } from "./getData";
import Preloader from "./Preloader";
import bg1 from "../images/geschiedenis-pancarte.png";
import overlay from "../images/overlay.png";

export default class Geschiedenis extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.contentOverRef = React.createRef();
    this.getData("view", "pages", "id=5");
    this.state = {
      pages: {},
      preloaded: false,
      loaded: 0
    };
    this.styleObject = {
      top: "auto",
      height: "62%",
      width: "85%",
      bottom: "7%",
      left: "8%"
    };
    this.colorObject = {
      color: "rgba(0,0,0,.7)"
    };
  }
  updatePreloaded = () => {
    let copy = this.state.loaded;
    copy++;
    this.setState({ loaded: copy });
    if (copy === 2) {
      this.setState({ preloaded: true });
    }
  };
  componentWillMount() {
    this.props.updatePage("geschiedenis");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
    this.show();
  }
  show = () => {
    setTimeout(() => {
      if (this.state.preloaded) {
        this.contentOverRef.current.classList.add("visible");
      } else {
        this.show();
      }
    }, 1000);
  };
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo | Geschiedenis</title>
          <link
            rel="canonical"
            href={"https://www.circusronaldo.be" + this.props.location.pathname}
          />
          <link rel="alternate" media="only screen and (max-width: 640px)" href={"https://m.circusronaldo.be/circus_of_theater"} />
          <meta property="og:title" content="Circus Ronaldo | Geschiedenis" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content="De geschiedenis van Circus Ronaldo."
          />
          <meta
            name="description"
            content="De geschiedenis van Circus Ronaldo."
          />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          src={bg1}
          className="hidden"
          alt="geschiedenis"
        />
        <img
          onLoad={this.updatePreloaded}
          src={overlay}
          className="hidden"
          alt="geschiedenis"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <Animated
            animationIn="bounceInDown"
            animationInDelay={0}
            animationOut="bounceOutUp"
            isVisible={this.props.page === "" ? false : true}
          >
            <div className="pancarte2Holder">
              <div className="pancarte2">
                <div className="pancarteImage2">
                  <img
                    className="pancarteImage2Image"
                    src={bg1}
                    alt="geschiedenis"
                  />
                  <div
                    className="textWrapper history"
                    ref={this.contentOverRef}
                    style={this.styleObject}
                  >
                    <div className="text" style={this.colorObject}>
                      {ReactHtmlParser(
                        this.state.pages["content_" + this.props.lang] +
                          "<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>"
                      )}
                    </div>
                  </div>
                  <img src={overlay} className="overlay" alt="" />
                </div>
              </div>
            </div>
          </Animated>
        )}
      </div>
    );
  }
}
