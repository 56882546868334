import React, { Component } from "react";

export default class Gallery extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  shouldComponentUpdate() {
    return true;
  }
  componentDidMount() {
    window.Galleria.loadTheme(
      "https://cdnjs.cloudflare.com/ajax/libs/galleria/1.5.7/themes/classic/galleria.classic.min.js"
    );
    window.Galleria.run(".galleria", {
      responsive: true,
      trueFullscreen: true,
      height: 0.65,
      showInfo: false,
      autoplay: 4000
    });
    window.Galleria.ready(function() {
      this.attachKeyboard({
        left: this.prev,
        right: this.next
      });
    });
  }
  render() {
    return (
      <div>
        <div className="galleria">
          {this.props.media.map((asset, index) => {
            if (asset.video !== null && asset.video.length >= 1) {
              return (
                <a
                  key={asset.id}
                  href={"https://www.youtube.com/watch?v=" + asset.video}
                >
                  <img
                    src={asset.foto.url}
                    alt={asset["info_" + this.props.lang]}
                  />
                </a>
              );
            }
            return (
              <img
                key={asset.id}
                src={asset.foto.url}
                alt={asset["info_" + this.props.lang]}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
