import React, { Component } from "react";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.background = React.createRef();
    // this.state = {};
    // this.lFollowX = 0;
    // this.lFollowY = 0;
    // this.x = 0;
    // this.y = 0;
    // this.friction = 1 / 30;
    this.bgStyle = {
      backgroundImage: "url(" + props.bg + ")"
    };
    // this.moveBackground();
  }
  componentDidMount() {
    // window.$(window).on("mousemove click", e => {
    //   var lMouseX = Math.max(
    //     -100,
    //     Math.min(100, window.$(window).width() / 2 - e.clientX)
    //   );
    //   var lMouseY = Math.max(
    //     -100,
    //     Math.min(100, window.$(window).height() / 2 - e.clientY)
    //   );
    //   this.lFollowX = (20 * lMouseX) / 100;
    //   this.lFollowY = (10 * lMouseY) / 100;
    // });
  }

  // moveBackground = () => {
  //   this.x += (this.lFollowX - this.x) * this.friction;
  //   this.y += (this.lFollowY - this.y) * this.friction;
  //   const translate =
  //     "translate(" + this.x + "px, " + this.y + "px) scale(1.1)";

  //   window.$(this.background.current).css({
  //     "-webit-transform": translate,
  //     "-moz-transform": translate,
  //     transform: translate
  //   });
  //   window.requestAnimationFrame(this.moveBackground);
  // };
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      <div className="bgBackgroundWrapper">
        <div
          className="bgBackground"
          style={this.bgStyle}
          ref={this.background}
        />
      </div>
    );
  }
}
