import React, { Component } from "react";

import { Animated } from "react-animated-css";
import Fullscreen from "react-full-screen";
import { GoScreenFull, GoScreenNormal } from "react-icons/lib/go";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import menu from "./images/menu.png";
import map from "./images/map.png";
import hamburger from "./images/hamburger.png";
import back from "./images/back.png";
import { withStyles } from "react-critical-css";

import Home from "./components/Home";
import Map from "./components/Map";
import Welcome from "./components/Welcome";
import Voorstellingen from "./components/Voorstellingen";
import VoorstellingDetail from "./components/VoorstellingDetail";
import Geschiedenis from "./components/Geschiedenis";
import Preloader from "./components/Preloader";
import Media from "./components/Media";
import TourTickets from "./components/TourTickets";
import Contact from "./components/Contact";
import Newsletter from "./components/Newsletter";
import bg from "./images/bg.jpg";
import BackgroundAnimation from "./components/BackgroundAnimation";

import s from "./index.css";

class App extends Component {
  constructor(props) {
    super();
    this.myRef = React.createRef();
    this.bgRef = React.createRef();
    let lang = "nl";
    console.log("===> " + window.location.pathname.split("/")[1]);
    if (window.location.pathname.split("/")[1].length === 2) {
      lang = window.location.pathname.split("/")[1];
    }
    this.state = {
      fullscreen: false,
      bgBackground: true,
      fullScreenIcon: true,
      menuIcon: false,
      mapIcon: false,
      hamburger: false,
      back: false,
      page: "",
      lang: lang,
      preloaded: false
    };
  }
  componentDidMount() { }
  goFull = () => {
    this.setState({ fullscreen: true });
  };
  goNormal = () => {
    this.setState({ fullscreen: false });
  };

  setLang = lang => {
    this.setState({
      lang: lang
    });
  };

  showMenu = e => {
    e.persist();
    this.updatePage("");
    //console.log(this.myRef.current.history)
    setTimeout(() => {
      this.myRef.current.history.push(
        "/" +
        e.target.parentElement.dataset.lang +
        "/" +
        e.target.parentElement.dataset.page
      );
    }, 1250);
  };
  showMap = e => {
    e.persist();
    this.updatePage("");
    //console.log(this.myRef.current.history)
    setTimeout(() => {
      this.myRef.current.history.push("/" + this.state.lang + "/map");
    }, 1250);
  };
  showHome = e => {
    e.persist();
    this.updatePage("");
    //console.log(this.myRef.current.history)
    setTimeout(() => {
      this.myRef.current.history.push("/home");
    }, 1250);
  };
  updatePage = value => {
    this.setState({
      page: value
    });
  };

  setIcon = (visible, icon) => {
    this.setState({
      [icon]: visible
    });
  };
  getVoorstellingenFromLang = () => {
    let caption = "";
    switch (this.state.lang) {
      case "nl":
        caption = "voorstellingen";
        break;
      case "fr":
        caption = "spectacles";
        break;
      case "en":
        caption = "shows";
        break;
      default:
        caption = "";
    }
    return caption;
  };
  getWelkomBasedOnLang = () => {
    let caption = "";
    switch (this.state.lang) {
      case "nl":
        caption = "welkom";
        break;
      case "fr":
        caption = "bienvenue";
        break;
      case "en":
        caption = "welcome";
        break;
      default:
        caption = "";
    }
    return caption;
  };
  updatePreloaded = () => {
    this.setState({
      preloaded: true
    });
  };

  render() {
    return (
      <Fullscreen
        enabled={this.state.fullscreen}
        onChange={fullscreen => this.setState({ fullscreen })}
      >
        <img
          onLoad={this.updatePreloaded}
          src={bg}
          className="hidden"
          alt="geschiedenis"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={this.state.bgBackground}
          >
            <BackgroundAnimation bg={bg} />
          </Animated>
        )}
        <BrowserRouter
          ref={this.myRef}
          basename={process.env.REACT_APP_SUBFOLDER}
        >
          {/* <BrowserRouter ref={this.myRef}> */}
          <div>
            {/* <Redirect from="/" to="/home" /> */}
            <Switch>
              <Route
                path="/"
                exact
                render={props => (
                  <Home
                    setLang={this.setLang}
                    page={this.state.page}
                    updatePage={this.updatePage}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/home"
                exact
                render={props => (
                  <Home
                    setLang={this.setLang}
                    page={this.state.page}
                    updatePage={this.updatePage}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/map|fr/map|en/map)/"
                exact
                render={props => (
                  <Map
                    lang={this.state.lang}
                    setLang={this.setLang}
                    page={this.state.page}
                    updatePage={this.updatePage}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/welkom|fr/bienvenue|en/welcome)/"
                render={props => (
                  <Welcome
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/voorstellingen|fr/spectacles|en/shows)/"
                render={props => (
                  <Voorstellingen
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/geschiedenis|fr/histoire|en/history)/"
                render={props => (
                  <Geschiedenis
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/media|fr/media|en/media)/:album"
                render={props => (
                  <Media
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/media|fr/media|en/media)/"
                render={props => (
                  <Media
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/tour-tickets|fr/tour-tickets|en/tour-tickets)/:event"
                render={props => (
                  <TourTickets
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/tour-tickets|fr/tour-tickets|en/tour-tickets)/"
                render={props => (
                  <TourTickets
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/contact|fr/contact|en/contact)/"
                render={props => (
                  <Contact
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl/newsletter|fr/newsletter|en/newsletter)/"
                render={props => (
                  <Newsletter
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
              <Route
                path="/(nl|fr|en)/event/:id/:title"
                render={props => (
                  <VoorstellingDetail
                    lang={this.state.lang}
                    updatePage={this.updatePage}
                    page={this.state.page}
                    setIcon={this.setIcon}
                    {...props}
                  />
                )}
              />
            </Switch>

            <Animated
              animationInDelay={1500}
              isVisible={this.state.fullScreenIcon}
            >
              {this.state.fullscreen ? (
                <GoScreenNormal
                  className="fullscreenIcon"
                  onClick={this.goNormal}
                  size={30}
                  color="#F6E5A3"
                />
              ) : (
                  <GoScreenFull
                    className="fullscreenIcon"
                    onClick={this.goFull}
                    size={30}
                    color="#F6E5A3"
                  />
                )}
            </Animated>

            <Animated animationInDelay={100} isVisible={this.state.menuIcon}>
              <span
                data-page="/home"
                className="menuIcon"
                onClick={this.showHome}
              >
                <img src={menu} alt="menu" />
              </span>
            </Animated>

            <Animated animationInDelay={100} isVisible={this.state.mapIcon}>
              <span
                data-page="/map"
                data-lang={this.state.lang}
                className="mapIcon"
                onClick={this.showMap}
              >
                <img src={map} alt="map" />
              </span>
            </Animated>

            <Animated animationInDelay={100} isVisible={this.state.hamburger}>
              <span
                data-page={this.getWelkomBasedOnLang()}
                data-lang={this.state.lang}
                className="menu"
                onClick={this.showMenu}
              >
                <img src={hamburger} alt="menu" />
              </span>
            </Animated>

            <Animated animationInDelay={100} isVisible={this.state.back}>
              <span
                data-page={this.getVoorstellingenFromLang()}
                data-lang={this.state.lang}
                className="back"
                onClick={this.showMenu}
              >
                <img src={back} alt="back" />
              </span>
            </Animated>
          </div>
        </BrowserRouter>
      </Fullscreen>
    );
  }
}

export default withStyles(s)(App);
