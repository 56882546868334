import React, { Component } from "react";
import { Animated } from "react-animated-css";
//import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { getData } from "./getData";
import bg1 from "../images/contact-pancarte.png";
import Preloader from "./Preloader";
import Iframe from 'react-iframe'

export default class Contact extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.contentOverRef = React.createRef();
    this.getData("view", "pages", "id=6");
    this.state = {
      pages: {},
      preloaded: false,
      wrapperSettings: [
        {
          top: "auto",
          height: "56%",
          width: "85%",
          bottom: "7%",
          left: "8%"
        }
      ]
    };
    this.flexed = {
      "display": "flex",
      "justify-content": "center"
    }
  }

  show = () => {
    setTimeout(() => {
      if (this.state.preloaded) {
        this.contentOverRef.current.classList.add("visible");
      } else {
        this.show();
      }
    }, 1000);
  };
  updatePreloaded = () => {
    this.setState({ preloaded: true });
    this.show();
  };
  componentWillMount() {
    this.props.updatePage("contact");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(true, "mapIcon");
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo | Contact</title>
          <link
            rel="canonical"
            href={
              "https://www.circusronaldo.be/" + this.props.location.pathname
            }
          />
          <meta property="og:title" content="Circus Ronaldo | Contact" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta
            property="og:description"
            content="Contacteer Circus Ronaldo."
          />
          <meta name="description" content="Contacteer Circus Ronaldo." />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          src={bg1}
          className="hidden"
          alt="contact"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <Animated
            animationIn="bounceInDown"
            animationInDelay={0}
            animationOut="bounceOutUp"
            isVisible={this.props.page === "" ? false : true}
          >
            <div className="pancarte2Holder contact">
              <div className="pancarte2">
                <div className="pancarteImage2">
                  <img
                    className="pancarteImage2Image"
                    src={bg1}
                    alt="contact"
                  />
                  <div
                    className="textWrapper"
                    ref={this.contentOverRef}
                    style={this.state.wrapperSettings[0]}
                  >
                    {this.state.pages.id && (
                      <div style={this.flexed}>
                        <Iframe url={"https://www.circusronaldo.be/nieuwsbrief_" + this.props.lang + ".html"}
                          width="50%"
                          height="450px"
                          id="myId"
                          className="myClassname"
                          display="initial"
                          position="relative" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        )}
      </div>
    );
  }
}
