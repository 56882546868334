import React, { Component } from "react";
import { Animated } from "react-animated-css";
//import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { getData } from "./getData";
import Preloader from "./Preloader";
import EventWorldMap from "./EventWorldMap";
import bg1 from "../images/map-pancarte.png";
require("babel-polyfill");

export default class Contact extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.contentOverRef = React.createRef();
    this.getData("view", "pages", "id=6");
    this.state = {
      pages: {},
      preloaded: false,
      wrapperSettings: [
        {
          top: "auto",
          height: "66.5%",
          width: "89.5%",
          bottom: "3%",
          left: "5.55%",
          overflow: "hidden"
        }
      ]
    };
  }
  show = () => {
    setTimeout(() => {
      if (this.state.preloaded) {
        this.contentOverRef.current.classList.add("visible");
      } else {
        this.show();
      }
    }, 1000);
  };
  updatePreloaded = () => {
    this.setState({ preloaded: true });
    this.show();
  };
  componentWillMount() {
    this.props.updatePage("contact");
  }
  componentDidMount() {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(false, "mapIcon");
    this.props.setIcon(false, "back");
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo | Map</title>
          <link
            rel="canonical"
            href={
              "https://www.circusronaldo.be/" + this.props.location.pathname
            }
          />
          <meta property="og:title" content="Circus Ronaldo | Contact" />
          <meta
            property="og:url"
            content={
              "https://www.circusronaldo.be" + this.props.location.pathname
            }
          />
          <meta property="og:description" content="Circus Ronaldo's map." />
          <meta name="description" content="Circus Ronaldo's map." />
        </Helmet>
        <img
          onLoad={this.updatePreloaded}
          src={bg1}
          className="hidden"
          alt="contact"
        />
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <Animated
            animationIn="bounceInDown"
            animationInDelay={0}
            animationOut="bounceOutUp"
            isVisible={this.props.page === "" ? false : true}
          >
            <div className="pancarte2Holder contact">
              <div className="pancarte2">
                <div className="pancarteImage2">
                  <img
                    className="pancarteImage2Image"
                    src={bg1}
                    alt="contact"
                  />
                  <div
                    className="textWrapper"
                    ref={this.contentOverRef}
                    style={this.state.wrapperSettings[0]}
                  >
                    <Preloader />
                    {this.state.pages.id && (
                      <div className="mapHolder">
                        <EventWorldMap lang={this.props.lang} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        )}
      </div>
    );
  }
}
