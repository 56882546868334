import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import taalkeuze from "../images/taalkeuze.png";
// import crowd from "../images/crowd.png";
// import crowdOver from "../images/crowd_over.png";
import nlOver from "../images/nl-over.png";
import frOver from "../images/fr-over.png";
import enOver from "../images/en-over.png";
import Preloader from "./Preloader";

export default class Welcome extends Component {
  constructor(props) {
    super();
    this.nlOverRef = React.createRef();
    this.link = React.createRef();
    this.frOverRef = React.createRef();
    this.enOverRef = React.createRef();
    // this.crowdRef = React.createRef();

    this.state = {
      preloaded: false,
      loaded: 0
    };
  }
  updatePreloaded = () => {
    let copy = this.state.loaded;
    copy++;
    this.setState({ loaded: copy });
    if (copy === 4) {
      this.setState({ preloaded: true });
    }
  };
  componentDidMount() {
    this.props.updatePage("home");
    this.props.setIcon(false, "menuIcon");
    this.props.setIcon(false, "hamburger");
    this.props.setIcon(false, "back");
    this.props.setIcon(false, "mapIcon");
  }
  loadPage = e => {
    e.persist();
    this.props.setLang(e.target.dataset.lang);
    this.props.updatePage("");
    setTimeout(() => {
      this.props.history.push(
        e.target.dataset.lang + "/" + e.target.dataset.page
      );
    }, 800);
  };
  crowdPage = e => {
    this["link"].current.click()
  }
  onMouseOver = e => {
    this[e.target.dataset.lang + "OverRef"].current.style.visibility =
      "visible";
  };
  onMouseOut = e => {
    this[e.target.dataset.lang + "OverRef"].current.style.visibility = "hidden";
  };
  // onMouseOver2 = e => {
  //   this["crowdRef"].current.style.visibility = "visible";
  // };
  // onMouseOut2 = e => {
  //   this["crowdRef"].current.style.visibility = "hidden";
  // };  
  render() {
    return (
      <div>
        <Helmet>
          <title>Circus Ronaldo</title>
          <link rel="canonical" href="https://www.circusronaldo.be/home" />
          <link rel="alternate" media="only screen and (max-width: 640px)" href="https://m.circusronaldo.be" />
          <meta property="og:title" content="Circus Ronaldo" />
          <meta property="og:type" content="activity" />
          <meta property="og:url" content="https://www.circusronaldo.be/home" />
          <meta
            property="og:image"
            content="https://www.circusronaldo.be/images/logo.png"
          />
          <meta
            property="og:site_name"
            content="https://www.circusronaldo.be"
          />
          <meta
            property="og:description"
            content="Welkom op de website van Circus Ronaldo"
          />
          <meta
            name="description"
            content="Welkom op de website van Circus Ronaldo"
          />
          <meta
            name="keywords"
            content="circus,ronaldo,fidelis,fortibus,swing,wonderlijke,kerst,cucina,dell,arte,Nanosh,carnivale"
          />
        </Helmet>

        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={taalkeuze}
          alt="voorstellingen"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={nlOver}
          alt="voorstellingen"
        />
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={frOver}
          alt="voorstellingen"
        />
        {/* <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={crowdOver}
          alt="voorstellingen"
        /> */}
        {/* <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={crowd}
          alt="voorstellingen"
        />                 */}
        <img
          onLoad={this.updatePreloaded}
          className="hidden"
          src={enOver}
          alt="voorstellingen"
        />
{/* <a ref={this.link} href="https://nl.ulule.com/cinema-malfait/" className="hidden">crowdfunding</a> */}
        {!this.state.preloaded && <Preloader />}
        {this.state.preloaded && (
          <div>
            <Animated
              animationIn="bounceInDown"
              animationInDelay={200}
              animationOutDelay={200}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="hoverParent">
                <img
                  className="taalkeuze"
                  src={taalkeuze}
                  alt="language"
                  useMap="#Map"
                />
                <img
                  className="frOver"
                  ref={this.frOverRef}
                  data-page="bienvenue"
                  data-lang="fr"
                  src={frOver}
                  alt="Français"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
                <img
                  className="nlOver"
                  ref={this.nlOverRef}
                  data-page="welkom"
                  data-lang="nl"
                  src={nlOver}
                  alt="Nederlands"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
                <img
                  className="enOver"
                  ref={this.enOverRef}
                  data-page="welcome"
                  data-lang="en"
                  src={enOver}
                  alt="English"
                  onClick={this.loadPage}
                  onMouseOut={this.onMouseOut}
                />
              </div>
              <map name="Map" id="Map">
                <area
                  data-page="welkom"
                  data-lang="nl"
                  shape="poly"
                  alt="Nederlands"
                  coords="274,661,252,663,252,658,261,657,261,644,269,637,285,632,294,623,308,629,319,629,331,618,344,621,361,612,382,615,392,609,410,619,428,607,447,610,465,612,480,631,483,642,482,655,470,656,455,667,443,673,423,668,413,663,407,672,396,678,373,673,357,680,352,683,341,681,331,674,325,680,320,686,308,688,299,692,295,686,292,680,283,678,283,668"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
                <area
                  data-page="bienvenue"
                  data-lang="fr"
                  shape="poly"
                  alt="Français"
                  coords="208,657,220,659,226,667,239,663,253,665,274,660,283,669,282,678,294,681,298,691,293,698,296,706,298,710,306,705,307,710,300,717,292,723,281,727,271,734,262,736,253,745,232,736,228,737,224,740,213,740,204,743,202,750,192,752,175,748,159,747,146,738,145,724,141,720,141,705,145,698,138,688,145,680,174,678,176,673,193,676,200,664"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
                <area
                  data-page="welcome"
                  data-lang="en"
                  shape="poly"
                  alt="English"
                  coords="504,631,520,629,526,625,535,621,543,625,548,630,570,628,587,636,600,635,601,646,610,651,614,658,611,671,611,683,600,691,584,687,567,687,557,691,539,689,526,691,500,688,482,696,458,689,456,669,465,658,481,655,483,641,493,633"
                  onMouseOver={this.onMouseOver}
                  onClick={this.loadPage}
                />
              </map>
            </Animated>
            <Animated
              animationIn="fadeInDownBig"
              animationInDelay={0}
              animationOutDelay={0}
              animationOut="fadeOutUpBig"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="logo" />
            </Animated>
            <Animated
              animationIn="fadeInDownBig"
              animationInDelay={0}
              animationOutDelay={0}
              animationOut="fadeOutUpBig"
              isVisible={this.props.page === "" ? false : true}
            >
            {/* <div className="hoverParent2">
              
              <img
                  className="crowd"
                  src={crowd}
                  alt="crowdfunding"
                  useMap="#Map2"
                  onClick={this.crowdPage}
                />
                
              <img
                  className="crowdOver"
                 ref={this.crowdRef}
                  src={crowdOver}
                  alt="Crowdfunding"
                onClick={this.crowdPage}
                 onMouseOut={this.onMouseOut2}
                />
                </div> */}
                {/* <map name="Map2" id="Map2">
                <area
                  shape="poly"
                  alt="Nederlands"
                  coords="63,651,466,678,436,1367,27,1319"
                 onMouseOver={this.onMouseOver2}
                  // onClick={this.crowdPage}
                 
                />
                </map> */}
            </Animated>
          </div>
        )}
      </div>
    );
  }
}
