import React, { Component } from "react";
import { Animated } from "react-animated-css";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { getData, slugify } from "./getData";
import Preloader from "./Preloader";

export default class VoorstellingDetail extends Component {
  constructor(props) {
    super();
    this.getData = getData;
    this.contentOverRef = React.createRef();
    this.getData("view", "voorstellingen", "id=" + props.match.params.id);
    this.state = {
      voorstellingen: {},
      preloaded: false
    };
  }
  updatePreloaded = () => {
    this.setState({ preloaded: true });
  };
  componentWillMount() {
    this.props.updatePage("voorstellingen-detail");
  }
  componentDidMount = () => {
    this.props.setIcon(true, "menuIcon");
    this.props.setIcon(true, "hamburger");
    this.props.setIcon(true, "back");
    this.props.setIcon(true, "mapIcon");
    this.show();
  };
  show = () => {
    setTimeout(() => {
      if (this.state.preloaded) {
        this.contentOverRef.current.classList.add("visible");
      } else {
        this.show();
      }
    }, 1000);
  };
  render() {
    var styleObject = {};
    var colorObject = {};
    if (this.state.voorstellingen.id) {
      styleObject = {
        top: this.state.voorstellingen.style_top,
        height: this.state.voorstellingen.style_height,
        width: this.state.voorstellingen.style_width
      };
      colorObject = {
        color: this.state.voorstellingen.style_color
      };
    }
    if (this.state.voorstellingen.id) {
      return (
        <div>
          <Helmet>
            <title>
              {"Circus Ronaldo | Voorstelling " +
                this.state.voorstellingen["title_" + this.props.lang]}
            </title>
            <link
              rel="canonical"
              href={
                "https://www.circusronaldo.be" + this.props.location.pathname
              }
            />
            <link rel="alternate" media="only screen and (max-width: 640px)" href={"https://m.circusronaldo.be/voorstelling/"+this.props.location.pathname.split('/').reverse()[0]} />
            <meta
              property="og:title"
              content={
                "Circus Ronaldo | Voorstelling " +
                this.state.voorstellingen["title_" + this.props.lang]
              }
            />
            <meta
              property="og:url"
              content={
                "https://www.circusronaldo.be" + this.props.location.pathname
              }
            />
            <meta
              property="og:description"
              content={
                "Lees hier all info over de voorstelling " +
                this.state.voorstellingen["title_" + this.props.lang] +
                " van Circus Ronaldo."
              }
            />
            <meta
              name="description"
              content={
                "Lees hier alle info over de voorstelling " +
                this.state.voorstellingen["title_" + this.props.lang] +
                " van Circus Ronaldo."
              }
            />
          </Helmet>

          <img
            src={this.state.voorstellingen.image.url}
            alt="voorstelling"
            className="hidden"
            onLoad={this.updatePreloaded}
          />
          {!this.state.preloaded && <Preloader />}
          {this.state.preloaded && (
            <Animated
              animationIn="bounceInDown"
              animationInDelay={0}
              animationOutDelay={0}
              animationOut="bounceOutUp"
              isVisible={this.props.page === "" ? false : true}
            >
              <div className="pancarte2Holder">
                <div className="pancarte2">
                  <div className="pancarteImage2">
                    <img
                      className="pancarteImage2Image"
                      src={this.state.voorstellingen.image.url}
                      alt="contact"
                    />

                    <div
                      className="textWrapper"
                      ref={this.contentOverRef}
                      style={styleObject}
                    >
                      <div className="text" style={colorObject}>
                        {ReactHtmlParser(
                          "<p><a href='" +
                            process.env.REACT_APP_HOST +
                            this.props.lang +
                            "/tour-tickets/" +
                            slugify(
                              this.state.voorstellingen[
                                "title_" + this.props.lang
                              ]
                            ) +
                            "'>Tickets</a></p>" +
                            this.state.voorstellingen[
                              "content_" + this.props.lang
                            ]
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Animated>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
