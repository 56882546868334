import React, { Component } from "react";
import ReactMapboxGl, { Marker, ZoomControl, Cluster } from "react-mapbox-gl";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { getBounds } from "./helpers";
import Preloader from "./Preloader";
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoidmVyaHVsc3RkIiwiYSI6IlBxeHN0cGMifQ.0Wc0ATXtqXfJSuDzc5s3kQ#4.65/12.92/23.97",
  scrollZoom: false
});

// "pk.eyJ1IjoidmVyaHVsc3RkIiwiYSI6ImNqaTIxbzk1MjFqcTgzcG9lM2xwYXQwd2UifQ.cnJ7OmuC2Zo92oI3HD8_Kg",

export default class EventWorldMap extends Component {
  constructor() {
    super();
    this.state = {
      events: [],
      loaded: false
    };
  }
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API2_URL + this.props.lang)
      .then(result => {
        const allEvents = result.data.events;
        console.log(allEvents);
        this.setState({
          events: [...allEvents],
          loaded: true
        });
      });
  }
  clusterStyle = {
    backgroundColor: "rgb(85, 10, 14)",
    width: "32px",
    height: "32px",
    color: "white",
    borderRadius: "50%",
    textAlign: "center",
    border: "1px solid white",
    display: "block",
    paddingTop: "5px",
    boxShadow: "0px 0px 10px #000"
  }
  getCoordinatesBounds() {
    const copy = this.state.events;
    let coordinates = [];
    for (let event of copy) {
      const coordinate = event.location.split(",");
      coordinates.push({
        lng: parseFloat(coordinate[1]),
        lat: parseFloat(coordinate[0])
      });
    }
    const bound = getBounds(coordinates);
    console.log(bound);
    return bound;
  }
  clusterMarker = (coordinates,pointCount) => (
    <Marker coordinates={coordinates}  style={this.clusterStyle}>
      {pointCount}
    </Marker>
  );
  render() {
    return (
      <div style={{height:'100%'}}>
        {!this.state.loaded && <Preloader />}
        {this.state.loaded && (
          <div style={{height:'100%'}}>
            <Map
              // eslint-disable-next-line
              style="mapbox://styles/verhulstd/cjsku69c85beq1gp01rw17rlv"
              // onClick={(map, e) => {
              //   console.log(map, e);
              // }}
              fitBounds={this.getCoordinatesBounds()}
              fitBoundsOptions={{ padding: 30, linear: true, offset: [0, 0] }}
              containerStyle={{
                height: "100%",
                width: "100%"
              }}
            >
              <ZoomControl />
              <Cluster ClusterMarkerFactory={this.clusterMarker}>
              {this.state.events.map((event, index) => {
                const coordinates = event.location.split(",");
                return (
                  <Marker
                    key={index}
                    coordinates={[coordinates[1], coordinates[0]]}
                    anchor="bottom"
                  >
                    <Popup
                      flowing
                      hoverable
                      trigger={
                        <Icon name="marker" size="big" className="markerIcon" />
                      }
                      wide
                      on={["click", "hover"]}
                      position="top center"
                    >
                      <div className="popupcontent">
                        <div>
                          <Image
                            src={
                              "https://www.circusronaldo.be/image.php/" +
                              event.image +
                              "?image=/uploads/" +
                              event.image +
                              "&width=220&height=160&cropratio=11:8"
                            }
                          />
                        </div>
                        <div>
                          <Header className="popupTitle">{event.title}</Header>
                          <p className="popupText">{ReactHtmlParser(event.desc)}</p>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
              </Cluster>
            </Map>
          </div>
        )}
      </div>
    );
  }
}
